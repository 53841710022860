import React, { useEffect, useState } from "react";
import phoneTwo from "./img/what-we-give.webp"
import treeMainChange from "./img/lilith-tree.webp"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import arrow from "./img/arrow.png"
// import money from "./img/money.svg"
import heart from "./img/heart.svg"
import confidence from "./img/confidence.svg";
import bonus from "./img/bonus.svg";
import alltime from "./img/alltime.svg"
import comfort from "./img/comfort.svg"
import video from "./img/video.svg"
import friendly from "./img/friendly.svg"
import procent from "./img/procent.svg"
import avans from "./img/avans.svg"
export const MainComponentUa = () => {
    const [serviceRef, serviceInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [aboutRef, aboutInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [feedbackRef, feedbackInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [defenceRef, defenceInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [revenuesRef, revenuesInView] = useInView({
        triggerOnce: true,
        rootMargin: "-10px 0px",
    });
    const [isIOS, setIsIOS] = useState(true);
    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsIOS(isSafari);
    }, []);
    return (
        <main className={isIOS ? "main" : "main notIOS"}>
            <section className="about service" id="about">
                <div className="about__container service__container">
                    <div className="about-block service-block">
                        <motion.h2
                            ref={aboutRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="service-block__title about-block__title">
                            Про нас
                        </motion.h2>
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="about-block__text">
                            <p>
                                <span className="fwbold">Lilith Agency</span> — це <span className="fwbold">інноваційна модельна агенція</span>, яке спеціалізується на допомозі у <span className="fwbold">розвитку акаунтів</span> на площадці Onlyfans. Наша місія полягає у<span className="fwbold">створення унікальних можливостей</span> для дівчат, щоб вони <span className="fwbold">досягли більшого успіху</span>, розкрили свій <span className="fwbold">потенціал</span> та вийшли на <span className="fwbold">стабільний дохід</span>.
                            </p>
                            <p>
                                <span className="fwbold">Гарантуємо індивідуальний підхід</span> до кожної моделі, розробляємо стратегії, а також забезпечуємо безперервну <span className="fwbold">підтримку</span> та <span className="fwbold">консультації</span>. Підходимо до питання просування і монетизації акаунтів системно. Розробляємо <span className="fwbold">ефективний</span> контент-план, який ґрунтується на <span className="fwbold">актуальних</span> трендах.
                            </p>
                            <p>
                                Наш <span className="fwbold">досвідчений</span> та <span className="fwbold">креативний</span> колектив працює над тим, щоб моделі могли залучити <span className="fwbold">більше підписників</span> та <span className="fwbold">збільшити</span> свою <span className="fwbold">популярність</span>. <span className="fwbold">Професійні</span> оператори ведуть активне листування з акаунтів цілодобово, що є ключовим фактором у досягненні <span className="fwbold">успіху</span>. Більшість анкет виходячи в <span className="fwbold">топовий рейтинг</span> сайту всього в <span className="fwbold">перший місяць</span> співпраці.
                            </p>
                        </motion.div>
                    </div>
                    <div className="about-content service-content">
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="about-content__img"><img src={treeMainChange} alt="Дерево Ліліт" /></motion.div>
                    </div>
                </div>
            </section>
            <section id="conditions" className="service" >
                <div className="service__container">
                    <div className="service-block">
                        <motion.h2
                            ref={serviceRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="service-block__title">
                            Переваги роботи з нами
                        </motion.h2>
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="service-block__img">
                            <img src={phoneTwo} alt="Команда" />
                        </motion.div>
                    </div>
                    <div className="service-content">
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 0.7 }}
                                className="service-content__number"><img src={confidence} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Конфіденційність</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>Ваша приватність - наш головний пріоритет. Гарантуємо абсолютну конфіденційність для всіх акаунтів.</p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 0.9 }}
                                className="service-content__number"><img style={{ height: "35px" }} src={heart} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Вільний графік</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>Самостійно керуєте своїм робочим часом, підлаштовуючи його під ваші потреби.</p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={bonus} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Бонуси</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Цінуємо старанну роботу та мотивуємо найкращих моделей різноманітними бонусами.
                                </p>
                            </motion.div>
                        </div>
                        {/* <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={money} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Стабильные выплаты</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    С нами вы можете рассчитывать на стабильные выплаты 2 раза в месяц.
                                </p>
                            </motion.div>
                        </div> */}
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={alltime} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Підтримка 24/7</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Фахівці доступні цілодобово, готові допомогти вирішити будь-які питання.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={comfort} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Комфортне робоче місце</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Ми надаємо комфортний офіс для продуктивної роботи і створення контенту.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={video} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Сучасне обладнання</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Модель має доступ до сучасної техніки та інструментів для створення якісного матеріалу.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={friendly} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Позитивна робоча атмосфера</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Робимо все можливе, щоб створити доброзичливе і надихаюче робоче оточення. У нас цінується колективний дух, підтримка з боку команди та можливість обмінюватися досвідом з однодумцями.
                                </p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="defence" id="defence">
                <div className="defence__container">
                    <div className="defence__content content-defence">
                        <motion.h2
                            ref={defenceRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="content-defence__title">Конфіденційність та безпека</motion.h2>
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="content-defence__subtitle">У Lilith Agency приділяється серйозна увага безпеці та конфіденційності. Ваш обліковий запис під надійним захистом!</motion.h2>
                        <div className="content-defence__block">
                            <ul className="content-defence__list">
                                <motion.li
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.5 }}
                                >Гарантуємо, що треті особи не мають доступу до вашого акаунту, особистої інформації, а також до фотографій та відеоматеріалів.</motion.li>
                                <motion.li
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >Вживаємо всіх заходів, щоб захистити вас від будь-яких витоків даних або порушень конфіденційності.</motion.li>
                            </ul>
                        </div>
                        <div className="content-defence__text"><motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.8 }}
                        >
                            Ми створюємо безпечне оточення, де ви можете вільно розвивати свою кар'єру, знаючи, що ваша приватність у надійних руках.
                        </motion.p></div>
                    </div>
                    <div className="defence__block">
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 1 }}
                            className="defence__img">
                            <img src="https://www.winheller.com/fileadmin/redaktion/dateien/data-protection-law-germany.webp" alt="" />
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="revenues" id="revenues">
                <div className="revenues__container">
                    <motion.h2
                        ref={revenuesRef}
                        initial={{ opacity: 0, y: -100 }}
                        animate={revenuesInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="revenues__title">Прибутки</motion.h2>
                    <motion.h2
                        initial={{ opacity: 0, y: 100 }}
                        animate={revenuesInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="revenues__subtitle">У нас є всі необхідні інструменти, щоб наші моделі могли досягти стабільного доходу. Однак, важливо пам'ятати, що він насамперед залежить від власної мотивації та бажання працювати.</motion.h2>
                    <div className="revenues__block">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={revenuesInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="revenues__block-card block-card">
                            <h2 className="block-card__title">Високі відсотки <img src={procent} alt="" /></h2>
                            <div className="block-card__text"><p>
                                Заробітна плата починається з 25 відсотків на старті співпраці, і вже за 4 місяці роботи ви можете розраховувати на 30% від доходу з вашого акаунта.
                            </p></div>
                            <div className="block-card__img">
                                <img src={procent} alt="" />
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={revenuesInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="revenues__block-card block-card">
                            <h2 className="block-card__title">Аванс <img src={avans} alt="" /></h2>
                            <div className="block-card__text">
                                <p>
                                    Для вашого комфорту і впевненості в завтрашньому дні, ми надаємо аванс вже в перший день роботи.
                                </p>
                                <p>
                                    У вас є унікальна можливість конвертувати свою красу і харизму в стабільний заробіток. Ми готові бути поруч, підтримувати ваші амбіції та супроводжувати вас на шляху до успіху!
                                </p>
                            </div>
                            <div className="block-card__img">
                                <img src={avans} alt="" />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="feedback" id="feedback">
                <div className="feedback__container">
                    <motion.h2
                        ref={feedbackRef}
                        initial={{ opacity: 0, y: -100 }}
                        animate={feedbackInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5 }}
                        className="feedback__title">Відгуки</motion.h2>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={feedbackInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="feedback__block">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={feedbackInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="swiper-button-prev feedback__swiper-prev"><img src={arrow} alt="стрелка лево" /></motion.div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            grabCursor={true}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={
                                {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }
                            }
                            modules={[Keyboard, Pagination, Navigation]}
                            className="feedback__swiper"
                        >
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Аліна В.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Працюючи з Lilith Agency вже рік, я можу сказати, що маю тільки позитивні враження. Тут стабільний дохід, вільний графік і приємна робоча атмосфера. Нарешті я відчуваю впевненість у завтрашньому дні.
                                </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Марина П.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Завдяки Lilith Agency, моя кар'єра стрімко злетіла. Лише кілька місяців роботи, і я вже вийшла на дохід у 3000$. Мені подобається, що моїм акаунтом займаються професіонали, і мені залишається лише створювати контент. Величезне спасибі всій команді за такі результати!                                </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Катерина Р.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Раніше я самостійно вела акаунт на Onlifans і вдавалося заробляти до 500 доларів на місяць. Однак, щойно вирішила почати співпрацю з Lilith Agency, уже в перший місяць мій дохід зріс до більш ніж 1500 доларів. Це дивовижно! Дякую, що допомогли мені досягти таких результатів.                                </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Юлія А.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Я довго обирала, з якою агенцією почати співпрацю, і зупинилася на Lilith Agency. І з кожним днем я переконуюся, що зробила правильний вибір. Тут не тільки високий дохід, а й професійний підхід до роботи.
                                </p></div>
                            </SwiperSlide>

                        </Swiper>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={feedbackInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="swiper-button-next feedback__swiper-next"><img src={arrow} alt="стрелка право" /></motion.div>
                    </motion.div>
                </div>
            </section>
        </main >
    )
}