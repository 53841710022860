import React from "react";
export const FooterComponent = ({ setLanguage }) => {
    return (<footer className="footer">
        <div className="footer__container">
            <div className="footer__block"></div>
        </div>
        <div className="copyright">
            <h2 className="copyright-text">copyright © 2023</h2>
        </div>
    </footer>)
}