import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"
import { NavLink } from "react-router-dom"
export const HeaderComponentUa = () => {
    const [scrolled, setScroll] = useState(0);
    const [isIOS, setIsIOS] = useState(true);

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        setIsIOS(isSafari);
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события при монтировании
        window.addEventListener("scroll", handleScroll);

        // Возвращаем функцию очистки обработчика при размонтировании компонента
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <header className={isIOS ? "header" : "header notIOS"} id="header">
            <div className="header__container">
                <div className="header-block">
                    <div className="header-block__title">
                        <motion.h1
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}>
                            LILITH
                        </motion.h1>
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}>
                            AGENCY
                        </motion.h2>
                    </div>
                    <motion.h2
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.9 }}
                        className="header-block__text">Beauty meets success</motion.h2>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 1 }}>
                        <button className={scrolled > 300 ? "header-block__reg-button hide-to-scroll" : "header-block__reg-button"}>
                            <NavLink to="https://t.me/LilithAgencyBot" className="header-block__reg-button-a" href="#"><span className="header-block__reg-button-span">Залишити заявку</span></NavLink>
                        </button>
                    </motion.div>
                </div>
            </div>
        </header >
    )
}