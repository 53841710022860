import React
, { useState, useEffect }
    from "react";
import logo from "./img/logo.svg";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink, useNavigate } from "react-router-dom";
import gmail from "./img/gmail.svg"
import telegram from "./img/telegram.svg"
import { scroller } from "react-scroll"
function ScrollNavLink({ to, setBurger, ...props }) {
    const navigate = useNavigate();

    const handleClick = async (event) => {
        event.preventDefault();
        setBurger(false)
        // Перенаправляем на главную страницу, если href не равно '/'
        if (window.location.pathname !== '/') {
            navigate('/');
            // Ждем небольшой задержки перед выполнением плавного скролла
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        // Выполняем плавный скролл к элементу
        scroller.scrollTo(to, {
            smooth: true,
            offset: -50,
            duration: 500,
        });
    };

    return (
        <NavLink {...props} to="#" onClick={handleClick} />
    );
}
export const NavBarComponent = ({ setLanguage, language }) => {
    const [scrolled, setScroll] = useState(0);
    const [burger, setBurger] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleLanguageChange = (selectedLanguage) => {
        setIsOpen(false)
        setLanguage(selectedLanguage);
    }
    useEffect(() => {
        // Здесь вы можете добавить логику для изменения стилей body
        const body = document.body;
        if (burger) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
        // Возвращаем функцию для очистки эффекта
        return () => {
            body.classList.remove('overflow-hidden');
        };
    }, [burger]);
    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события при монтировании
        window.addEventListener("scroll", handleScroll);

        // Возвращаем функцию очистки обработчика при размонтировании компонента
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <div className={scrolled > 50 ? "navbar scrolled" : "navbar"}>
            <div className={`navbar__container ${scrolled > 50 && 'scrolled'} ${burger ? 'active' : ''}`}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="navbar__logo">
                    <ScrollNavLink setBurger={setBurger} to="header">
                        <img className="navbar__logo-svg" src={logo} alt="Лилит" />
                    </ScrollNavLink>
                </motion.div>
                <nav className="navbar__list defoult">
                    <ul className="navbar__list-block">
                        <motion.li
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 1 }}
                            className="navbar__list-elemet">
                            <ScrollNavLink setBurger={setBurger} to="about">
                                о нас
                            </ScrollNavLink></motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 1.1 }}
                            className="navbar__list-elemet">
                            <ScrollNavLink setBurger={setBurger} to="conditions">
                                Преимущества
                            </ScrollNavLink></motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 1.4 }}
                            className="navbar__list-elemet"><ScrollNavLink setBurger={setBurger} to="defence">
                                Конфиденциальность
                            </ScrollNavLink></motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 1.3 }}
                            className="navbar__list-elemet"><ScrollNavLink setBurger={setBurger} to="revenues">
                                Доходы
                            </ScrollNavLink></motion.li>
                        <motion.li
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 1.2 }}
                            className="navbar__list-elemet"><ScrollNavLink setBurger={setBurger} to="feedback">
                                отзывы
                            </ScrollNavLink></motion.li>
                    </ul>
                </nav>
                <motion.div
                    // initial={{ opacity: 0, y: -50 }}
                    // animate={{ opacity: 1, y: 0 }}
                    // transition={{ duration: 1, delay: 1.5 }}
                    className={scrolled > 300 || burger ? "navbar__button-container vissible-to-scroll" : "navbar__button-container"}>
                    <motion.button
                        // initial={{ opacity: 0, y: -50 }}
                        // animate={{ opacity: 1, y: 0 }}
                        // transition={{ duration: 1, delay: 1.6 }}

                        className="navbar__reg-button">
                        <NavLink to="https://t.me/LilithAgencyBot" onClick={() => setBurger(false)} className="navbar__reg-button-a" href="#">
                            <span className="navbar__reg-button-span defoult">Оставить заявку</span>
                            <span className="navbar__reg-button-span">Заявка</span>
                        </NavLink>
                    </motion.button>
                </motion.div>
                <div className="navbar__social defoult">
                    <motion.a
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 1.3 }}
                        href="https://t.me/llthmngr"><img src={telegram} alt="" /></motion.a>
                    <motion.a
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 1.4 }}
                        href="mailto:Lilithagency1@gmail.com"><img src={gmail} alt="" /></motion.a>
                </div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 3 }}
                    transition={{ duration: 1, delay: 1.6 }}
                    className="navbar__language defoult"
                >
                    {language === "ukraine" ? <div style={{ width: "30px", marginBottom: "5px", cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)} >UA</div>
                        : <div style={{ cursor: "pointer", width: "30px", marginBottom: "5px" }} onClick={() => setIsOpen(!isOpen)} >RU</div>}
                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {language === "ukraine"
                                        ? <div
                                            alt="Russia"
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={() => handleLanguageChange("russian")}
                                        >RU</div>
                                        : <div
                                            alt="Ukraine"
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={() => handleLanguageChange("ukraine")}
                                        >UA</div>
                                    }
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
                <div className="navbar__burger">
                    <button className={burger ? "menu-btn active" : "menu-btn"} onClick={() => setBurger(!burger)}>
                        <span></span>
                    </button>
                </div>
            </div>
            <div className={burger ? "navbar__burger-menu active" : "navbar__burger-menu"}>
                <nav className={burger ? "navbar__list active" : "navbar__list"}>
                    <ul className="navbar__list-block">
                        <li className="navbar__list-elemet">
                            <ScrollNavLink setBurger={setBurger} to="about">
                                о нас
                            </ScrollNavLink></li>
                        <li className="navbar__list-elemet">
                            <ScrollNavLink setBurger={setBurger} to="conditions">
                                Преимущества
                            </ScrollNavLink></li>
                        <li className="navbar__list-elemet">
                            <ScrollNavLink setBurger={setBurger} to="defence">
                                Конфиденциальность
                            </ScrollNavLink>
                        </li>
                        <li className="navbar__list-elemet"><ScrollNavLink setBurger={setBurger} to="revenues">
                            Доходы
                        </ScrollNavLink></li>
                        <li className="navbar__list-elemet"><ScrollNavLink setBurger={setBurger} to="feedback">
                            отзывы
                        </ScrollNavLink></li>
                    </ul>
                </nav>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 3 }}
                    transition={{ duration: 1, delay: 1.6 }}
                    className="navbar__language"
                >
                    {language === "ukraine" ? <div style={{ width: "30px", marginBottom: "5px", cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)} >UA</div>
                        : <div style={{ cursor: "pointer", width: "30px", marginBottom: "5px" }} onClick={() => setIsOpen(!isOpen)} >RU</div>}
                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {language === "ukraine"
                                        ? <div
                                            alt="Russia"
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={() => handleLanguageChange("russian")}
                                        >RU</div>
                                        : <div
                                            alt="Ukraine"
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={() => handleLanguageChange("ukraine")}
                                        >UA</div>
                                    }
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
                <div className={burger ? "navbar__social active" : "navbar__social"}>
                    <a href="https://t.me/llthmngr"><img src={telegram} alt="" /></a>
                    <a href="mailto:Lilithagency1@gmail.com"><img src={gmail} alt="" /></a>
                </div>
                {/* <button className="navbar__reg-button">
                    <NavLink to="/req" className="navbar__reg-button-a" href="#"><span className="navbar__reg-button-span">Оставить заявку</span></NavLink>
                </button> */}
            </div>
        </div>
    );
};
