import React, { useEffect, useState } from "react";
import phoneTwo from "./img/what-we-give.webp"
import treeMainChange from "./img/lilith-tree.webp"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import arrow from "./img/arrow.png"
// import money from "./img/money.svg"
import heart from "./img/heart.svg"
import confidence from "./img/confidence.svg";
import bonus from "./img/bonus.svg";
import alltime from "./img/alltime.svg"
import comfort from "./img/comfort.svg"
import video from "./img/video.svg"
import friendly from "./img/friendly.svg"
import procent from "./img/procent.svg"
import avans from "./img/avans.svg"
export const MainComponent = () => {
    const [serviceRef, serviceInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [aboutRef, aboutInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [feedbackRef, feedbackInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [defenceRef, defenceInView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    const [revenuesRef, revenuesInView] = useInView({
        triggerOnce: true,
        rootMargin: "-10px 0px",
    });
    const [isIOS, setIsIOS] = useState(false);
    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsIOS(isSafari);
    }, []);
    return (
        <main className={isIOS ? "main" : "main notIOS"}>
            <section className="about service" id="about">
                <div className="about__container service__container">
                    <div className="about-block service-block">
                        <motion.h2
                            ref={aboutRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="service-block__title about-block__title">
                            О нас
                        </motion.h2>
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="about-block__text">
                            <p>
                                <span className="fwbold">Lilith Agency</span> — это <span className="fwbold">инновационное модельное агентство</span>, которое специализируется на помощи в <span className="fwbold">развитии аккаунтов</span> на площадке Onlyfans. Наша миссия заключается в <span className="fwbold">создании уникальных возможностей</span> для девушек, чтобы они <span className="fwbold">достигли большего успеха</span>, раскрыли свой <span className="fwbold">потенциал</span> и вышли на <span className="fwbold">стабильный доход</span>.
                            </p>
                            <p>
                                <span className="fwbold">Гарантируем индивидуальный подход</span> к каждой модели, разрабатываем стратегии, а также обеспечиваем непрерывную <span className="fwbold">поддержку</span> и <span className="fwbold">консультации</span>. Подходим к вопросу продвижения и монетизации аккаунтов системно. Разрабатываем <span className="fwbold">эффективный</span> контент-план, который основывается на <span className="fwbold">актуальных</span> трендах.
                            </p>
                            <p>
                                Наш <span className="fwbold">опытный</span> и <span className="fwbold">креативный</span> коллектив работает над тем, чтобы модели могли привлечь <span className="fwbold">больше подписчиков</span> и <span className="fwbold">увеличить</span> свою <span className="fwbold">популярность</span>. <span className="fwbold">Профессиональные</span> операторы ведут активную переписку с аккаунтов круглосуточно, что является ключевым фактором в достижении <span className="fwbold"><span className="fwbold"></span></span>. Большинство анкет выходя в <span className="fwbold">топовый рейтинг</span> сайта всего в <span className="fwbold">первый месяц</span> сотрудничества.
                            </p>
                        </motion.div>
                    </div>
                    <div className="about-content service-content">
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={aboutInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="about-content__img"><img src={treeMainChange} alt="Дерево Лилит" /></motion.div>
                    </div>
                </div>
            </section>
            <section id="conditions" className="service" >
                <div className="service__container">
                    <div className="service-block">
                        <motion.h2
                            ref={serviceRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="service-block__title">
                            Преимущества работы с нами
                        </motion.h2>
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="service-block__img">
                            <img src={phoneTwo} alt="Комманда" />
                        </motion.div>
                    </div>
                    <div className="service-content">
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 0.7 }}
                                className="service-content__number"><img src={confidence} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Конфиденциальность</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>Ваша приватность — наш главный приоритет. Гарантируем абсолютную конфиденциальность для всех аккаунтов.</p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 0.9 }}
                                className="service-content__number"><img style={{ height: "35px" }} src={heart} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Свободный график</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>Самостоятельно управляете своим рабочим временем, подстраивая его под ваши потребности.</p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={bonus} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Бонусы</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Ценим усердную работы и мотивируем лучших моделей разнообразными бонусами.
                                </p>
                            </motion.div>
                        </div>
                        {/* <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={money} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Стабильные выплаты</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    С нами вы можете рассчитывать на стабильные выплаты 2 раза в месяц.
                                </p>
                            </motion.div>
                        </div> */}
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={alltime} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Поддержка 24/7</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Специалисты доступны круглосуточно, готовые помочь решить любые вопросы.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={comfort} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Комфортное рабочее место</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Мы предоставляем комфортный офис для продуктивной работы и создания контента.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={video} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Современное оборудование</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Модель имеет доступ к современной технике и инструментам для создания качественного материала.
                                </p>
                            </motion.div>
                        </div>
                        <div className="service-content__block">
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={serviceInView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1 }}
                                className="service-content__number"><img src={friendly} alt="" /></motion.div>
                            <motion.h2
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.2 }}
                                className="service-content__title">Позитивная рабочая атмосфера</motion.h2>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={serviceInView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 1.4 }}
                                className="service-content__text">
                                <p>
                                    Делаем все возможное, чтобы создать дружелюбное и вдохновляющее рабочее окружение. У нас ценится коллективный дух, поддержка со стороны команды и возможность обмениваться опытом с единомышленниками.                                </p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="defence" id="defence">
                <div className="defence__container">
                    <div className="defence__content content-defence">
                        <motion.h2
                            ref={defenceRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="content-defence__title">Конфиденциальность и безопасность</motion.h2>
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="content-defence__subtitle">В Lilith Agency уделяется серьезное внимание безопасности и конфиденциальности. Ваша учетная запись под надежной защитой!</motion.h2>
                        <div className="content-defence__block">
                            <ul className="content-defence__list">
                                <motion.li
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.5 }}
                                >Гарантируем, что третьи лица не имеют доступа к вашему аккаунту, личной информации, а также к фотографиям и видеоматериалам.</motion.li>
                                <motion.li
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >Предпринимаем все меры, чтобы защитить вас от любых утечек данных или нарушений конфиденциальности.</motion.li>
                            </ul>
                        </div>
                        <div className="content-defence__text"><motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.8 }}
                        >
                            Мы создаем безопасное окружение, где вы можете свободно развивать свою карьеру, зная, что ваша приватность в надежных руках.
                        </motion.p></div>
                    </div>
                    <div className="defence__block">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={defenceInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 1 }}
                            className="defence__img">
                            <img src="https://www.winheller.com/fileadmin/redaktion/dateien/data-protection-law-germany.webp" alt="" />
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="revenues" id="revenues">
                <div className="revenues__container">
                    <motion.h2
                        ref={revenuesRef}
                        initial={{ opacity: 0, y: -100 }}
                        animate={revenuesInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="revenues__title">Доходы</motion.h2>
                    <motion.h2
                        initial={{ opacity: 0, y: 100 }}
                        animate={revenuesInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="revenues__subtitle">У нас есть все необходимые инструменты, чтобы наши модели могли достичь стабильного дохода. Однако, важно помнить, что он в первую очередь зависит от собственной мотивации и желания работать.</motion.h2>
                    <div className="revenues__block">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={revenuesInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="revenues__block-card block-card">
                            <h2 className="block-card__title">Высокие проценты <img src={procent} alt="" /></h2>
                            <div className="block-card__text"><p>
                                Заработная плата начинается с 25 процентов на старте сотрудничества, и уже через 4 месяца работы вы можете рассчитывать на 30% от дохода с вашего аккаунта.
                            </p></div>
                            <div className="block-card__img">
                                <img src={procent} alt="" />
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={revenuesInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="revenues__block-card block-card">
                            <h2 className="block-card__title">Аванс <img src={avans} alt="" /></h2>
                            <div className="block-card__text">
                                <p>
                                    Для вашего комфорта и уверенности в завтрашнем дне, мы предоставляем аванс уже в первый день работы.
                                </p>
                                <p>
                                    У вас есть уникальная возможность конвертировать свою красоту и харизму в стабильный заработок. Мы готовы быть рядом, поддерживать ваши амбиции и сопровождать вас на пути к успеху!
                                </p>
                            </div>
                            <div className="block-card__img">
                                <img src={avans} alt="" />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="feedback" id="feedback">
                <div className="feedback__container">
                    <motion.h2
                        ref={feedbackRef}
                        initial={{ opacity: 0, y: -100 }}
                        animate={feedbackInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5 }}
                        className="feedback__title">Отзывы</motion.h2>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={feedbackInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="feedback__block">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={feedbackInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="swiper-button-prev feedback__swiper-prev"><img src={arrow} alt="стрелка лево" /></motion.div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            grabCursor={true}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={
                                {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }
                            }
                            modules={[Keyboard, Pagination, Navigation]}
                            className="feedback__swiper"
                        >
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Алина В.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Работая с Lilith Agency уже год, я могу сказать, что у меня только положительные впечатления. Здесь стабильный доход, свободный график и приятная рабочая атмосфера. Наконец то я чувствую уверенность в завтрашнем дне.
                                </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Марина П.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Благодаря Lilith Agency, моя карьера стремительно взлетела. Всего пару месяцев работы, и я уже вышла на доход в 3000$. Мне нравится, что моим аккаунтом занимаются профессионалы, и мне остается лишь создавать контент. Огромное спасибо всей команде за такие результаты!                            </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Екатерина Р.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Ранее я самостоятельно вела аккаунт на Onlifans и удавалось зарабатывать до 500 долларов в месяц. Однако, как только решила начать сотрудничество с Lilith Agency, уже в первый месяц мой доход вырос до более чем 1500 долларов. Это удивительно! Спасибо, что помогли мне достичь таких результатов.                            </p></div>
                            </SwiperSlide>
                            <SwiperSlide className="feedback__swiper-slide">
                                <h2 className="feedback__swiper-name">Юлия А.</h2>
                                <div className="feedback__swiper-text"><p>
                                    Я долго выбирала, с каким агентством начать сотрудничество, и остановилась на Lilith Agency. И с каждым днем я убеждаюсь, что сделала правильный выбор. Здесь не только высокий доход, но и профессиональный подход к работе.                            </p></div>
                            </SwiperSlide>

                        </Swiper>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={feedbackInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="swiper-button-next feedback__swiper-next"><img src={arrow} alt="стрелка право" /></motion.div>
                    </motion.div>
                </div>
            </section>
        </main >
    )
}