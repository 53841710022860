import React, { useState, useEffect } from "react";
import { FooterComponent } from "./components/FooterComponent";
import { FooterComponentUa } from "./components/FooterComponentUa";
import { HeaderComponent } from "./components/HeaderComponent";
import { HeaderComponentUa } from "./components/HeaderComponentUa";
import { MainComponent } from "./components/MainComponent";
import { MainComponentUa } from "./components/MainComponentUa";
import { NavBarComponentUa } from "./components/NavBarComponentUa";
import { NavBarComponent } from "./components/NavBarComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/style.css";

function App() {
  // Получаем язык из localStorage или устанавливаем значение по умолчанию
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    return storedLanguage || 'ukraine';
  });

  useEffect(() => {
    // Сохраняем установленный язык в localStorage
    localStorage.setItem('selectedLanguage', language);
  }, [language]);

  const isUkraine = language === 'ukraine';

  return (
    <BrowserRouter>
      {isUkraine ? <NavBarComponentUa language={language} setLanguage={setLanguage} /> : <NavBarComponent language={language} setLanguage={setLanguage} />}
      <Routes>
        <Route path="/" element={isUkraine ? <HomePageUa setLanguage={setLanguage} /> : <HomePage setLanguage={setLanguage} />} />
      </Routes>
    </BrowserRouter>
  );
}

const HomePage = ({ setLanguage }) => {
  return (<>
    <HeaderComponent />
    <MainComponent />
    <FooterComponent setLanguage={setLanguage} />
  </>)
}

const HomePageUa = ({ setLanguage }) => {
  return (<>
    <HeaderComponentUa />
    <MainComponentUa />
    <FooterComponentUa setLanguage={setLanguage} />
  </>)
}

export default App;
